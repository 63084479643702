import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import { useQuery } from '@apollo/client';
import { isAfter } from 'date-fns';
import Image from 'next/image';
import Link from 'next/link';

import { CustomImage, Slider } from '@components/common';
import { CountDown } from '@components/common/CountDown';
import ImageFlashSale from '@assets/icons/flash-offer-title.png';
import ImageCountdown from '@assets/icons/bg-contador.png';
import { formatCurrency, sufixParse } from '@utils';
import { GetPromotionDocument } from '@generated/graphql';
import { useGTMDataLayer } from '@hooks';

export interface iPromotionalProduct {
  date_end: string;
  enabled: string;
  final_price: number;
  image: string;
  name: string;
  regular_price: number;
  sku: string;
  tags: {
    image: string;
    name: string;
  }[];
  url: string;
  __typename: string;
}

interface iFlashSaleBannerCard {
  index: number;
  promotionalProduct: iPromotionalProduct;
  handleExpirePromotionalProduct: () => void;
}

const FlashSaleBannerCard = ({
  promotionalProduct,
  index,
  handleExpirePromotionalProduct
}: iFlashSaleBannerCard) => {
  const { pushToDataLayer } = useGTMDataLayer();

  const handlePushToDatalayer = useCallback(() => {
    const promotionalProductsForDatalayer = {
      title: 'FlashOfferSalesBanner',
      ecommerce: {
        currencyCode: 'BRL',
        items: []
      }
    };

    const promotionId = promotionalProduct?.image.match(/\/([^/?#]+)\.jpg$/i);

    if (promotionId?.[1])
      promotionalProductsForDatalayer.ecommerce.items.push({
        promotion_id: promotionId?.[1],
        promotion_name: promotionalProduct?.name,
        creative_name: 'Flash offer',
        location_id: promotionalProduct?.url,
        creative_slot: index + 1
      } as never);

    pushToDataLayer('flashSaleBanner', promotionalProductsForDatalayer, false);
  }, [
    index,
    promotionalProduct?.image,
    promotionalProduct?.name,
    promotionalProduct?.url,
    pushToDataLayer
  ]);

  const promotionalProductRegularPrice = useMemo(() => {
    return formatCurrency(promotionalProduct?.regular_price);
  }, [promotionalProduct?.regular_price]);

  const promotionalProductFinalPrice = useMemo(() => {
    return formatCurrency(promotionalProduct?.final_price);
  }, [promotionalProduct?.final_price]);

  const productSufix = sufixParse('/p');

  return (
    <div
      className="relative md:h-[336px] flex justify-center border-box rounded-md"
      onClick={handlePushToDatalayer}
    >
      <CustomImage
        src={ImageCountdown}
        alt="Imagem de fundo do contador"
        fill
        className="rounded-md z-[1]"
        style={{
          objectFit: 'cover'
        }}
      />
      <div className="z-[2] relative p-4 flex flex-col h-full w-full items-center">
        <div className="w-full">
          <p className="w-full flex items-center justify-center flex-nowrap uppercase text-white font-bold text-sm">
            {/* {'Aproveite a '} */}
            <Image
              src={ImageFlashSale}
              alt="Titulo Oferta Relampago"
              className="w-3/4"
            />
          </p>

          <CountDown
            endDate={promotionalProduct?.date_end}
            onFinish={handleExpirePromotionalProduct}
            legendFontSize="text-[10px]"
          />

          {/* TODO: remove this `replace` solution after backend return the simple product urls */}
          <Link
            href={`/${productSufix(promotionalProduct?.url?.split('/')[3])}`
              .replace('-configurable', '')
              .replace('-config', '')}
          >
            <div className="w-full my-4 md:mt-4 md:mb-0 py-[16px] px-[12px] cursor-pointer rounded-md bg-white flex text-primary-medium bn-oferta-relampago">
              {promotionalProduct?.image && (
                <div className="w-[120px] h-[120px] relative flex justify-center items-center">
                  <CustomImage
                    src={promotionalProduct?.image}
                    alt={promotionalProduct?.image}
                    width={120}
                    height={120}
                  />

                  {promotionalProduct?.tags?.map((tag, i) => (
                    <div
                      className="flex flex-wrap absolute top-0 right-0"
                      key={i}
                    >
                      <span>
                        <CustomImage
                          width={40}
                          height={40}
                          src={tag.image}
                          alt={tag.name}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <span className="w-full bn-oferta-relampago flex flex-col justify-center ml-4">
                <h1 className="text-base font-bold line-clamp-2 md:max-w-[121px] bn-oferta-relampago">
                  {promotionalProduct?.name}
                </h1>

                <span className="md:mt-8 flex flex-col items-start">
                  <p className="text-sm text-gray-darkest line-through">
                    de {promotionalProductRegularPrice}
                  </p>
                  <strong className="text-base font-bold">
                    por
                    <b className="text-xl ml-2 font-bold">
                      {promotionalProductFinalPrice}
                    </b>
                  </strong>
                </span>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const FlashSaleBanner = ({ setPromotionalDateIsExpired }) => {
  const [pushedToDatalayer, setPushedToDatalayer] = useState(false);
  const { pushToDataLayer } = useGTMDataLayer();

  const [promotionalProducts, setPromotionalProducts] = useState(
    [] as iPromotionalProduct[]
  );

  const { data: dataPromotion } = useQuery(GetPromotionDocument, {
    variables: { sku: '' },
    fetchPolicy: 'cache-and-network'
  });

  const handleExpirePromotionalProduct = useCallback(() => {
    const productsFiltered = promotionalProducts.filter(
      product => !isAfter(new Date(), new Date(product.date_end))
    );

    if (productsFiltered?.length > 0) {
      setPromotionalProducts(productsFiltered);
    } else {
      setPromotionalDateIsExpired(true);
    }
  }, [promotionalProducts, setPromotionalDateIsExpired]);

  useEffect(() => {
    const promotionalProduct =
      dataPromotion?.promotionBf as iPromotionalProduct[];

    if (!isEmpty(promotionalProduct)) {
      const productsFiltered = promotionalProduct.filter(
        product => !isAfter(new Date(), new Date(product.date_end))
      );

      setPromotionalProducts(productsFiltered);

      const promotionalProductsForDatalayer = {
        title: 'FlashOfferSalesBanner',
        ecommerce: {
          currencyCode: 'BRL',
          items: []
        }
      };

      productsFiltered.map((product, index) => {
        const promotionId = product?.image.match(/\/([^/?#]+)\.(jpg|png)$/i);

        if (promotionId?.[1])
          promotionalProductsForDatalayer.ecommerce.items.push({
            promotion_id: promotionId?.[1],
            promotion_name: product?.name,
            creative_name: 'Flash offer',
            creative_slot: index + 1,
            location_id: product?.url
          } as never);
      });

      if (!pushedToDatalayer) {
        pushToDataLayer(
          'flashSaleBannerPageView',
          promotionalProductsForDatalayer
        );
        setPushedToDatalayer(true);
      }
    }
  }, [dataPromotion?.promotionBf, pushToDataLayer, pushedToDatalayer]);

  return (
    <>
      {promotionalProducts?.length > 0 && (
        <Slider
          infinite={promotionalProducts?.length > 1 ? true : false}
          showDots={promotionalProducts?.length > 1 ? true : false}
          autoPlay
          autoPlaySpeed={3000}
          arrows={false}
          activeDotsClass="bg-tertiary-darkest"
          inactiveDotsClass="bg-secondary-lightest"
        >
          {promotionalProducts?.map((product, index) => (
            <FlashSaleBannerCard
              key={product.sku}
              index={index}
              promotionalProduct={product}
              handleExpirePromotionalProduct={handleExpirePromotionalProduct}
            />
          ))}
        </Slider>
      )}
    </>
  );
};

export default FlashSaleBanner;
