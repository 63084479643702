import { gql, useQuery } from '@apollo/client';
import { CustomImage } from '@components/common';
import SkeletonComponent from '@components/common/Skeleton';
import { forwardRef, Key, useMemo } from 'react';

export const GET_INFO_BANNER = gql`
  query {
    cmsBlocks(identifiers: "info-banner-home") {
      block_content {
        content
        image
      }
    }
  }
`;

interface InfoBannerData {
  content: string;
  image: any;
}

const ContentBlock = ({ content, image }: InfoBannerData) => {
  const htmlContent = useMemo(() => {
    const pattern = /[[\]]/g;

    const replacement = match => {
      return match === '[' ? '<' : '>';
    };

    if (typeof content === 'string')
      return content.replace(pattern, replacement);

    return '';
  }, [content]);

  return (
    <div
      className={`text-gray-darkest style-modules-info-3Xz w-[192px] flex-shrink-0 md:flex-shrink-auto md:col-span-1 md:w-auto flex flex-col md:flex-row justify-center items-center text-center md:text-left text-xs md:text-sm`}
    >
      <span className="min-w-8 h-8 mb-2 md:mb-0 md:mr-3 inline-block">
        <CustomImage
          src={image}
          width={32}
          height={32}
          className="w-full h-full"
          alt="Ícone"
          loading="lazy"
        />
      </span>
      <div
        className="relative"
        dangerouslySetInnerHTML={{
          __html: `<p>${htmlContent}</p>`
        }}
      >
        {/* <p className="title" html /> */}
      </div>
    </div>
  );
};

export const InfoBanner = forwardRef<HTMLDivElement>((_, ref) => {
  const { data, loading } = useQuery(GET_INFO_BANNER);

  return (
    <>
      <section className="container mx-auto my-3 lg:my-6 lg:mt-8" ref={ref}>
        <article className="mx-0 p-0 p-4 flex overflow-x-scroll md:overflow-x-visible p-2 md:p-0 md:grid md:grid-cols-4 gap-5 ">
          {loading &&
            !data?.cmsBlocks &&
            Array.from({ length: 4 }).map((_, index) => (
              <SkeletonComponent width="100%" height="2.5rem" key={index} />
            ))}
          {data?.cmsBlocks?.block_content.map(
            (
              { content, image }: InfoBannerData,
              index: Key | null | undefined
            ) => <ContentBlock key={index} content={content} image={image} />
          )}
        </article>
      </section>
    </>
  );
});

InfoBanner.displayName = 'InfoBanner';
