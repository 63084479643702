'use client';

import { memo } from 'react';
import { ProductsHighlight, ProductsSection } from '@common';
import { usePushToDataLayerOnce } from '@hooks';
import { BrandsSlider } from './BrandsSlider';
import MainBanner from './MainBanner';
import { NewsBanner } from './NewsBanner';
import { InfoBanner } from '@components/pages/Home/InfoBanner';
import { useInView } from 'react-intersection-observer';
import FlashSaleBanner from './FlashSaleBanner';
import { useState } from 'react';
import CallToActionBanner from './CallToActionBanner';

export const Home = memo(
  ({
    categoryShowcase,
    categoryListData,
    productHighLightCategory
  }: {
    categoryShowcase: any;
    categoryListData: any;
    productHighLightCategory: any;
  }) => {
    const [promotionalDateIsExpired, setPromotionalDateIsExpired] =
      useState(false);
    usePushToDataLayerOnce('cms', { title: 'HomePage', page_type: 'homepage' });

    const { inView, ref } = useInView({ triggerOnce: true });

    return (
      <>
        <MainBanner />
        <InfoBanner ref={ref} />
        {!promotionalDateIsExpired && (
          <div className="lg:hidden w-full h-[fit-content] flex-col border-box p-1 px-3 overflow-hidden w-full border-box relative add-commit">
            <FlashSaleBanner
              setPromotionalDateIsExpired={setPromotionalDateIsExpired}
            />
          </div>
        )}
        <CallToActionBanner />
        <BrandsSlider />

        {inView && (
          <>
            <div className="mt-6">
              <ProductsHighlight
                categoryList={productHighLightCategory?.categoryList}
              />
            </div>
            <NewsBanner />

            {categoryShowcase?.showcase.map(category => (
              <ProductsSection
                classShowcase={`gtm-produtos${category.name.replace(/\s/g, '')}`}
                key={category.category_id}
                title={category.name}
                categoryName={category.name}
                subTitle={category.subtitle}
                categoryIds={[category.category_id]}
                categoryList={categoryListData?.categoryList}
              />
            ))}
          </>
        )}
      </>
    );
  }
);

Home.displayName = 'Home';
